import React, { useMemo } from "react";
import { Typography, Paper } from "@mui/material";

const AdminSummary = ({ orders }) => {
  const summary = useMemo(() => {
    const totalRevenue = orders
      .filter((order) => order.invoice_status === "paid")
      .reduce((sum, order) => sum + order.paid_cents, 0) / 100;

    const pixOrders = orders.filter(
      (order) => order.invoice_status === "paid" && order.payment_method === "pix"
    );

    const cardOrders = orders.filter(
      (order) => order.invoice_status === "paid" && order.payment_method === "iugu_credit_card"
    );

    const pixFees = pixOrders.reduce((sum, order) => sum + order.paid_cents * 0.0089, 0) / 100;
    const cardFees =
      cardOrders.reduce(
        (sum, order) => sum + order.paid_cents * 0.0235 + 25,
        0
      ) / 100;

    const totalFees = pixFees + cardFees;
    const adminShare = totalRevenue * 0.1;
    const netRevenue = adminShare - totalFees;
    const developerShare = adminShare * 0.15;

    return {
      totalRevenue,
      pixFees,
      cardFees,
      totalFees,
      adminShare,
      netRevenue,
      developerShare,
      pixCount: pixOrders.length,
      cardCount: cardOrders.length,
    };
  }, [orders]);

  return (
    <Paper elevation={3} sx={{ padding: 4, marginBottom: 3 }}>
      <Typography variant="h5" gutterBottom>
        Resumo do Administrador
      </Typography>
      <Typography variant="body1">
        <strong>Total Vendido:</strong> R${summary.totalRevenue.toFixed(2)}
      </Typography>
      <Typography variant="body1">
        <strong>Total de Taxas Pix:</strong> R${summary.pixFees.toFixed(2)}
      </Typography>
      <Typography variant="body1">
        <strong>Total de Taxas Cartão:</strong> R${summary.cardFees.toFixed(2)}
      </Typography>
      <Typography variant="body1">
        <strong>Somatório de Taxas:</strong> R${summary.totalFees.toFixed(2)}
      </Typography>
      <Typography variant="body1">
        <strong>Quantidade de Pix:</strong> {summary.pixCount}
      </Typography>
      <Typography variant="body1">
        <strong>Quantidade de Cartões:</strong> {summary.cardCount}
      </Typography>
      <Typography variant="body1">
        <strong>Parte do Administrador:</strong> R${summary.adminShare.toFixed(2)}
      </Typography>
      <Typography variant="body1">
        <strong>Valor Líquido:</strong> R${summary.netRevenue.toFixed(2)}
      </Typography>
      <Typography variant="body1">
        <strong>Parte do Desenvolvedor:</strong> R${summary.developerShare.toFixed(2)}
      </Typography>
    </Paper>
  );
};

export default AdminSummary;