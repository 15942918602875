import React, { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Typography, CircularProgress, Card, CardContent, CardMedia, Divider } from '@mui/material';
import EventIcon from '@mui/icons-material/Event';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import BadgeIcon from '@mui/icons-material/Badge';
import PersonIcon from '@mui/icons-material/Person';
import api from '../../api';
import { EventContext } from '../../contexts/EventContext';

const PassDetailsPage = () => {
  const { passId } = useParams();
  const [pass, setPass] = useState(null);
  const [loading, setLoading] = useState(true);

  const { events } = useContext(EventContext);

  useEffect(() => {
    const fetchPassDetails = async () => {
      try {
        const response = await api.get(`/passes/${passId}`);
        setPass(response.data);
      } catch (error) {
        console.error('Erro ao buscar detalhes do passe:', error);
        alert('Erro ao carregar os detalhes do passe.');
      } finally {
        setLoading(false);
      }
    };

    fetchPassDetails();
  }, [passId]);

  if (loading) {
    return (
      <Box sx={{ textAlign: 'center', mt: 4 }}>
        <CircularProgress />
        <Typography variant="body1" sx={{ mt: 2 }}>
          Carregando detalhes do passe...
        </Typography>
      </Box>
    );
  }

  if (!pass) {
    return (
      <Box sx={{ textAlign: 'center', mt: 4 }}>
        <Typography variant="body1" color="error">
          Detalhes do passe não encontrados.
        </Typography>
      </Box>
    );
  }

  const relatedEvent = events.find(event => String(event.id) === String(pass.event_id));

  return (
    <Box
      sx={{
        p: 2,
        bgcolor: '#f8f8f8',
        minHeight: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Card
        sx={{
          maxWidth: 400,
          width: '100%',
          borderRadius: 3,
          boxShadow: 3,
          bgcolor: '#ffffff',
          overflow: 'hidden',
        }}
      >
        {/* QR Code */}
        <CardMedia
          component="img"
          height="100%"
          image={pass.qrcode_url || 'Erro: QR Code não encontrado'}
          alt="QR Code do Passe"
          sx={{
            objectFit: 'contain',
            mx: 'auto',
            width: '100%',
          }}
        />

        {/* Card Content */}
        <CardContent sx={{ p: 3 }}>
          <Typography
            variant="h4"
            color="primary"
            textAlign="center"
            sx={{ fontWeight: 'bold', textTransform: 'uppercase' }}
          >
            Metaingressos
          </Typography>

          <Typography
            variant="body2"
            color="text.secondary"
            textAlign="center"
            sx={{ mb: 3 }}
          >
            www.metaingressos.com.br
          </Typography>

          <Divider sx={{ my: 2 }} />

          {/* Nome do Evento */}
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
            <EventIcon color="primary" sx={{ mr: 1 }} />
            <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
              {relatedEvent ? relatedEvent.name : 'Evento não encontrado'}
            </Typography>
          </Box>

          {/* Localização */}
          {relatedEvent && (
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <LocationOnIcon color="primary" sx={{ mr: 1 }} />
              <Typography variant="body1" color="text.secondary">
                {relatedEvent.location || 'Localização não disponível'}
              </Typography>
            </Box>
          )}

          {/* Datas */}
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
            <EventIcon color="primary" sx={{ mr: 1 }} />
            <Typography variant="body1" color="text.secondary">
              {`${new Date(pass.start_time).toLocaleString('pt-BR')} - ${new Date(pass.end_time).toLocaleString('pt-BR')}`}
            </Typography>
          </Box>

          <Divider sx={{ my: 2 }} />

          {/* Informações Pessoais */}
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
            <PersonIcon color="primary" sx={{ mr: 1 }} />
            <Typography variant="body1" color="text.secondary">
              {pass.name || 'Nome não disponível'}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
            <BadgeIcon color="primary" sx={{ mr: 1 }} />
            <Typography variant="body1" color="text.secondary">
              CPF: {pass.cpf || 'Não disponível'}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
            <PhoneIcon color="primary" sx={{ mr: 1 }} />
            <Typography variant="body1" color="text.secondary">
              {pass.phone || 'Telefone não disponível'}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
            <EmailIcon color="primary" sx={{ mr: 1 }} />
            <Typography variant="body1" color="text.secondary">
              {pass.email || 'Email não disponível'}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
            <EmailIcon color="primary" sx={{ mr: 1 }} />
            <Typography variant="body1" color="text.secondary">
              {pass.identifier || 'Identifier não disponível'}
            </Typography>
          </Box>

          <Divider sx={{ my: 2 }} />

          <Typography
            variant="caption"
            color="text.secondary"
            sx={{ display: 'block', textAlign: 'center', mt: 2 }}
          >
            Este passe é pessoal e intransferível. Para entrar no evento, apresente este ingresso e um documento de
            identificação com foto.
          </Typography>
        </CardContent>
      </Card>
    </Box>
  );
};

export default PassDetailsPage;
